import * as React from "react"

import { StyleRoot } from 'radium';
import {graphql} from "gatsby"

import Head from "../templates/Head";
import WebSite from "../templates/WebSite"
import BlogPostHomepagePreview from "../templates/BlogPostHomepagePreview";

const HomePage = ({data}) => {
    let posts = data.allContentfulBlogPost.edges.map((post, key) => {
        return (
            <BlogPostHomepagePreview key={key} post={post.node} />
        );
    })

    return (
        <StyleRoot style={{height: '100%'}}>
          <Head />
          <WebSite>{posts}</WebSite>
        </StyleRoot>
    )
}

export const query = graphql`
  {
    allContentfulBlogPost (sort: {order: DESC, fields: createdAt}) {
      edges {
        node {
          slug
          title
          shortDescription
          createdAt
        }
      }
    }
  }
`
export default HomePage
