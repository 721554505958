import React from "react"

import * as s from '../styles/website'

const BlogPostHomepagePreview = ({post}) => {
    console.log(post);
    post.slug = "/" + post.slug;
    post.createdAt = post.createdAt.split('T')[0];

    return (
        <article style={s.homepageArticleWrapper}>
                <p style={s.homepageArticleDateTime}>{post.createdAt}</p>
                <h2 style={s.homepageArticleTitle}><a href={post.slug}>{post.title}</a></h2>
                <p style={s.homepageArticleDescription}>{post.shortDescription}</p>
        </article>
    );
};

export default BlogPostHomepagePreview;
