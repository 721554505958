import React from "react"

import { StaticImage } from "gatsby-plugin-image";
import Radium, { StyleRoot } from "radium";
import * as s from "../styles/website";
import NavigationMenu from "./NavigationMenu";
import Copyright from "./Copyright";
import PresentationLong from "./PresentationLong";
import KevinsPicture from "./KevinsPicture";
import GoogleAnalytics from "./GoogleAnalytics";

const WebSite = (props) => {
    return (
        <>
            <GoogleAnalytics />
            <div style={s.wrapper}>
                <header style={s.header}>
                    <div style={s.innerPadding}>
                        <KevinsPicture />
                        <PresentationLong />
                        <NavigationMenu />
                        <Copyright />
                    </div>
                </header>
                <main style={s.main}>
                    <div style={s.innerPadding}>
                        {props.children}
                    </div>
                </main>
            </div>
        </>
    )
}

export default Radium(WebSite);
